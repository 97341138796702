import '../../assets/css/login/login.css';
import React, { Component } from 'react';
import { connect} from "react-redux";
import {adCall, ticketPrintCall} from "../../actions";
import {eventConstants, globalData} from "../../constants";
import {Linear, Power4, TweenMax} from "gsap";
import adAction from "../../actions/adAction";
import popupLogoutAction from "../../actions/popupLogout.action";
import EventBus from "../../constants/EventBus";
import ReactPlayer from "../../ReactPlayer/ReactPlayer.js"

class MediaItem extends Component {

    constructor(props){
        super(props);
        this.theComponent = React.createRef();
        this.videoPlayer = React.createRef();
        this.mediaParentRef = React.createRef();
        this.customHtml = React.createRef();
        this.mediaHtml = React.createRef();

        this.myTween = null;
        this.myTweenVideo = null;
        this.myTimeout = null;

        this.theHeight = 0;
        this.clickTick = 0;
        this.clickTimeout = null;
    }

    componentDidMount() {
        let {loginState} = this.props;

        if(this.myTimeout){
            clearTimeout(this.myTimeout);
            this.myTimeout = null;
        }
        this.processData();

        if(loginState.config.type === "queue"){
            return;
        }
//        window.addEventListener('resize', this.resize);

        this.resize();

    }

//	componentWillMount() {
//		window.addEventListener("keydown", this.monitorLogout);
//	}

	monitorLogout = (event) => {		
		if (event.type == "backbutton") {
			event.preventDefault();

			let {popupLogoutAction} = this.props;
			popupLogoutAction({
				type: eventConstants.SHOW_POPUP_LOGOUT
			});
		}		
	}

    resize = () => {

        if (this.videoPlayer.current) {
            let {loginState} = this.props;
            if(loginState.config.type === "ads") {
                this.videoPlayer.current.style.height = ((document.querySelector(".videoPlayer").offsetWidth / 16 * 9) + "px");
            }
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.myTimeout){
            clearTimeout(this.myTimeout);
            this.myTimeout = null;
        }
        this.processData();
    }

    processData(){
        let propData = this.props;
        if(propData.idx === propData.current){
            this.showComponent();
        } else if(propData.idx === propData.previous){
            this.hideComponent();
        }
    }

    showComponent() {
        let propData = this.props;


        if (this.theComponent.current) {
            if (propData.item.type === "image") {

                this.myTween = TweenMax.to(this.theComponent.current, 1, {autoAlpha: 1, ease: Power4.easeNone});

                this.myTimeout = setTimeout(() => {
                    let {adAction} = this.props;
                    adAction({
                        type: eventConstants.SHOW_NEXT_MEDIA_ITEM
                    });
                }, propData.item.delay * 1000);

                this.resize();
            }
        }
        if (this.customHtml.current) {

            let initialPosition = 100;
            if (document.querySelector(".headingModified")) {
                initialPosition = document.querySelector(".headingModified").offsetHeight + 50;
            }

            TweenMax.set(this.customHtml.current, {y: initialPosition});

            TweenMax.to(this.mediaHtml.current, 1, {autoAlpha: 1, ease: Power4.easeNone});
            this.myTween = TweenMax.to(this.customHtml.current, 1, {autoAlpha: 1, ease: Power4.easeNone});

            let referenceHeight = 0;
            try {
                referenceHeight = document.querySelector(".smartq-img-fader").offsetHeight;
            }catch (e) {
                try {
                    referenceHeight = document.querySelector(".ads").offsetHeight;
                }catch (e) {
                    
                }
            }


            setTimeout(() => {
                let {adAction} = this.props;
                TweenMax.to(this.customHtml.current, propData.item.speed, {
                    y: Math.round(-this.mediaHtml.current.offsetHeight + referenceHeight - initialPosition),
                    ease: Power4.easeNone,
                    onComplete: (()=>{
                        setTimeout(() => {
                            adAction({
                                type: eventConstants.SHOW_NEXT_MEDIA_ITEM
                            });
                        }, propData.item.wait.after * 1000);
                    })
                });
            }, propData.item.wait.before * 1000 + 1000);
        }

        if (this.videoPlayer.current) {
            if (propData.item.type === "video") {
                this.videoPlayer.current.volume = propData.item.volume / 100;
                this.videoPlayer.current.autoplay = true;

                this.resize();
            }
        }

        if(this.videoPlayer.current){
           this.theHeight = this.videoPlayer.current.offsetHeight;
        }
    }

    hideComponent() {
        let propData = this.props;
        if (this.theComponent.current) {
            if (propData.item.type === "image") {
                this.myTween = TweenMax.to(this.theComponent.current, 1, {autoAlpha: 0, ease: Power4.easeOut});
            }
        }else if (this.mediaHtml.current) {
            if (propData.item.type === "html") {
                this.myTween = TweenMax.to(this.mediaHtml.current, 1, {autoAlpha: 0, ease: Power4.easeOut});
            }
        }
    }

    videoEnded(){
        let {adAction} = this.props;
        adAction({
            type: eventConstants.SHOW_NEXT_MEDIA_ITEM
        });
    }

    videoError(){
        let {adAction} = this.props;
        adAction({
            type: eventConstants.SHOW_NEXT_MEDIA_ITEM
        });
    }

    handleImageLoaded() {
        if (this.theComponent.current) {
            this.theHeight = this.theComponent.current.offsetHeight;

            if (document.querySelector(".ads") !== null) {
                if (this.theHeight > document.querySelector(".ads").offsetHeight) {
                    this.addClassToElement(this.mediaParentRef.current, "huge-size");
                }
            } else if (document.querySelector(".smartq-img-fader") !== null) {
                if (this.theHeight > document.querySelector(".smartq-img-fader").offsetHeight) {
                    this.addClassToElement(this.mediaParentRef.current, "huge-size");
                }
            }
        }
    }

    addClassToElement(pElem, pClass){
        if(!pElem.classList.contains(pClass)){
            pElem.classList.add(pClass);
        }
    }

    itemClicked(e){
        if(e) e.preventDefault();

        this.clickTick++;
        if(!this.clickTimeout){
            this.clickTimeout = setTimeout(() => {
                if(this.clickTick >= 3){
                    let {popupLogoutAction} = this.props;
                    popupLogoutAction({
                        type: eventConstants.SHOW_POPUP_LOGOUT
                    });
                }
                this.clickTick = 0;
                this.clickTimeout = null;
            }, 700);
        }
    }

    createMarkup() {
        return {
            __html: this.props.item.html
        };
    }

    ref = player => {
        this.player = player
    };

    render() {
        let propData = this.props;

        if(this.props.skinSettings){
            if(this.props.skinSettings.show === false){
              return null;
            }
        }
        if (this.props.item.type === "html") {
            return (
                <div className="media-html" onClick={(e) => this.itemClicked(e)} ref={this.mediaHtml}>
                    <div dangerouslySetInnerHTML={this.createMarkup()} ref={this.customHtml} className="custom-html"/>
                </div>
            )
        } else if (this.props.item.type === "image") {
            return (
                <div className="media-parent" ref={this.mediaParentRef} onClick={(e) => this.itemClicked(e)}>
                    <img src={this.props.item.src} ref={this.theComponent} onLoad={this.handleImageLoaded.bind(this)}/>
                </div>

            );
        } else if (this.props.item.type === "video") {
            if (propData.idx === propData.current) {
                let loopVar = false;
                if (this.props.totalItems) {
                    loopVar =  this.props.totalItems === 1 ? true : false;
                }
                return (
                    <div className="media-parent">
                        <ReactPlayer
                            ref={this.ref}
                            url={this.props.item.src}
                            preload={"true"}
                            volume={0}
                            width={"100%"}
                            height={"100%"}
                            loop={loopVar}
                            playing={true}
                            onEnded={this.videoEnded.bind(this)}
                        />
                    </div>
                );
            }
        }

        return (
            null
        );
    }
}
/*
 <video ref={this.videoPlayer} className="videoPlayer" onClick={(e) => this.itemClicked(e)}
                               preload="auto"
                               autoPlay={true}
                               onEnded={this.videoEnded.bind(this)}
                               onError={this.videoError.bind(this)}
                        >
                            <source src={this.props.item.src} type="video/mp4"/>
                        </video>
 */
const mapStateToProps = (state) => ({
    loginState: state.loginState
});

const mapDispatchToProps =  {
    adAction,
    popupLogoutAction
};


export default connect(mapStateToProps, mapDispatchToProps)(MediaItem);
