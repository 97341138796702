import {eventConstants, globalConstants} from "../constants";


const loginAction = (payload) => {
    switch (payload.type) {


        case eventConstants.POPUP_ACTION_EXIT_SUCCESS:
            componentActionName(payload);
            return {
                type: eventConstants.POPUP_ACTION_EXIT_SUCCESS,
                payload
            };
        case eventConstants.HIDE_LOGIN_ERR_POPUP:
            componentActionName(payload);
            return {
                type: eventConstants.HIDE_LOGIN_ERR_POPUP,
                payload
            };
        case eventConstants.HIDE_LOGIN_POPUP:
            componentActionName(payload);
            return {
                type: eventConstants.HIDE_LOGIN_POPUP,
                payload
            };
        case eventConstants.SHOW_EXIT_CONFIRMATION:
            componentActionName(payload);
            return {
                type: eventConstants.SHOW_EXIT_CONFIRMATION,
                payload
            };

        case eventConstants.CHANGE_LANGUAGE:
            componentActionName(payload);
            return {
                type: eventConstants.CHANGE_LANGUAGE,
                payload
            };

        case eventConstants.SWITCH_TRIGGERED:
            componentActionName(payload);
            return {
                type: eventConstants.SWITCH_TRIGGERED,
                payload
            };

        case eventConstants.SAVE_TO_LOCAL_STORAGE:
            localStorage.setItem(globalConstants.LOCAL_STORAGE_OBJ, payload.val);
            return {
                type: eventConstants.SAVE_TO_LOCAL_STORAGE,
                payload
            };
        case globalConstants.API:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        case eventConstants.LAUNCH_SETUP:
            componentActionName(payload);
            return {
                type: payload.type,
                payload
            };

        default:
            return {
                type: payload.type,
                payload
            }
    }
};


const componentActionName = (payload) => {
    if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- ACTION: loginAction     ", payload.type);
        console.log("payload:  ", payload);
    }
};


export default loginAction;