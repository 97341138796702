import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from "redux";
import reducers from "./reducers";
import {timerMiddleware} from "./middleware/timerMiddleware"
import {API} from "./middleware/API.middleware";

const createStoreWithMiddleware = applyMiddleware(API, timerMiddleware)(createStore);

/*
if(window.location.host.indexOf('localhost:3000') < 0) {
    console.log = function(){};
}*/

const startAppReact = () => {

    ReactDOM.render(
        <Provider store={createStoreWithMiddleware(reducers)}>
            <App/>
        </Provider>,
        document.getElementById('root')
    );
    //registerServiceWorker();
};

const startAppCordova = () => {

    if (window.StatusBar){
        window.StatusBar.hide();
    }


    if (window.powermanagement) {
        window.powermanagement.acquire();
    }

    if (window.screen) {
        //window.screen.orientation.lock('landscape');

		var change = function() {
			var body	= document.getElementsByTagName('body')[0];

			console.log(window.screen.orientation.type);

			switch (window.screen.orientation.type)
			{
				case "landscape-primary":
				case "landscape-secondary":
				case "landscape":
					body.classList.remove("portrait"); 
					body.classList.add("landscape");
				break;

				default:
					body.classList.remove("landscape"); 
					body.classList.add("portrait");
				break;
			
			}
		};

		change();

		window.addEventListener(
			"orientationchange", 
			change
		);
    }

//	document.addEventListener("backbutton", event => {
//		console.log(event);
//	}, false);


//	document.addEventListener("keydown", event => {
//		console.log(event);
//	});


    if (window.MobileAccessibility) {
        window.MobileAccessibility.usePreferredTextZoom(false);
    }

    ReactDOM.render(
        <Provider store={createStoreWithMiddleware(reducers)}>
            <App/>
        </Provider>,
        document.getElementById('root')
    );

    //registerServiceWorker();
};

if(window.cordova) {
    document.addEventListener('deviceready', startAppCordova, false);
} else {
    startAppReact();
}

