import {eventConstants, globalConstants} from "../constants";
import axios from "axios";

const timers = {};
const timersInProgress = {};
let count = 0;

const runTimerFunc = (store, next, action, newPayload, timerName) => {
    if (newPayload.callType === eventConstants.TIMER_API) {
        let callUrl = newPayload.url;
        if(newPayload.count === true){
            callUrl += "&key=" + count;
            count++;
        }
        axios.post(callUrl, {})
       .then(response => {
           if (response.data.request.status === "ok") {
               timersInProgress[timerName] = false;
               if(!response.data.response){
                   response.data.response = []
               }
               let newAction = {
                   type: newPayload.success,
                   payload: {
                       deepPayload: {...newPayload},
                       ...response.data
                   }
               };
               store.dispatch(newAction);
           } else {
               console.log(" !!! request OK but status = err ");
               store.dispatch({
                   type: newPayload.fail,
                   payload:{
                       failResponse: response.data
                   }
               });
           }
       })
       .catch(function (error) {
           console.log(error);
           store.dispatch({
               type: newPayload.fail,
               payload: {
                   failResponse: error
               }
           });
       });
    } else  if (newPayload.callType === eventConstants.EXECUTE_EVENT) {
        store.dispatch({
            type: newPayload.success,
            payload: { }
        });
    }
};

export const timerMiddleware = store => next => action => {
    if (action.type === eventConstants.TIMER_START) {
        middlewareName(action);

        let newPayload = action.payload;
        let timerName = newPayload.timerName;
        let timerInterval = newPayload.timerInterval;

        clearInterval(timers[timerName]);
        timersInProgress[timerName] = false;

        timers[timerName] = setInterval(() => {
            if (timersInProgress[timerName] === false) {
                timersInProgress[timerName] = true;
                runTimerFunc(store, next, action, newPayload.secondCall, timerName);
            }
        }, timerInterval);

        if(newPayload.hasOwnProperty("skipInstantCall")){
            if(newPayload.skipInstantCall === true){
                return;
            }
        }
        runTimerFunc(store, next, action, newPayload.secondCall, timerName);

    } else if (action.type === eventConstants.TIMER_STOP) {
        middlewareName(action);
        const {timerName} = action.payload;
        try {
            clearInterval(timers[timerName]);
            timersInProgress[timerName] = false;
        } catch (e) { console.log(" NON existing timer: ", timerName); }
    } else {
        return next(action);
    }
};

const middlewareName = (action) => {
    if(globalConstants.DEV_ENABLE_LOG === true){
        console.log("--- MIDDLEWARE: timerMiddleware     ", action.type);
        console.log("action:  ", action);
    }
};