import {globalConstants} from "./index";

export const isEmpty = function(obj) {
    for(let key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
};

export const detectPrintPermission = (pArray) =>{
    let enablePrint = false;

    for (let idx = 0; idx < pArray.length; idx++) {
        if (pArray[idx].selected === true) {
            enablePrint = true;
            break;
        }
    }

    return enablePrint;
};

export const translateText = (loginState, pText) => {
    return loginState.language[loginState.currentLang]._texts[pText];
};


export const translateModuleText = (loginState, pText) => {
    return loginState.language[loginState.currentLang][loginState.config.type].texts[pText];
};

export const translateTextPopup = (loginState, pMode, pValue, pBtn) => {
    if(pBtn){
        try {
            return loginState.language[loginState.currentLang][loginState.config.type].popups[pMode][pValue][pBtn];
        }catch (e) {

        }
    }

    try {
        return loginState.language[loginState.currentLang][loginState.config.type].popups[pMode][pValue];
    }catch (e) {

    }

};

export const translateTextAlternate = (pParentObj, pMode, pValue, pBtn) => {
    try {
        if(pBtn){
            return pParentObj.popups[pMode][pValue][pBtn];
        }else{
            return pParentObj.popups[pMode][pValue];
        }
    }catch (e) {

    }
};

export const translateTexts = (pNode, pValue) => {
    return pNode[pValue];
};


export const buildUrlFormat = pUrl => {
    pUrl = pUrl.trim();
    if(!pUrl){
        return pUrl;
    }

    if (!pUrl.includes(globalConstants.API_SECURE_PROTOCOL) && !pUrl.includes(globalConstants.API_UNSECURE_PROTOCOL)) {
        pUrl = globalConstants.API_SECURE_PROTOCOL + pUrl;
    }

    pUrl += globalConstants.API_URL ;

    return pUrl;
};

export const getLocalUserData = (pField) => {
    let localData = localStorage.getItem(pField);
    if(localData){
        return localData;
    }
    return "";
};

export const isEqual = function (value, other) {

    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    var compare = function (item1, item2) {

        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }

        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;
};