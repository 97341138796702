import {buttonsConstants, eventConstants, globalConstants} from "../constants";
import {isEmpty, isEqual} from "../constants/utils";
import {cloneDeep} from "lodash";

const initialState = {
    oldFeedData: {},

    status: globalConstants.OPERATOR_WAITING,
    hideContent: true,

    acceptMiddleState: true,

    activeButton: "",
    postponed: [],
    dropped: [],
    manual: [],

    inworkData:{},
    perms: {},
    ticketInfo: [],
    ticketHtml: "",
    ticketStatus: -1,
    ticketNumber: -1,
    disabledRecall: "",

    recallIteration: 0,
    recallEnabled: true,
    showSuccessNote: false,

    inputFieldValue:""
};

const operatorState = (state = initialState, action) => {
    switch (action.type) {

        case eventConstants.INPUT_FIELD_UPDATE_OPERATOR:
            reducerName(state, action);
            return {
                ...state,
                inputFieldValue: action.payload.textVal
            };
        case eventConstants.POPUP_ACTION_EXIT_SUCCESS:
            reducerName(state, action);

            // emanuel
            console.log(" EXIT !!!!!!! ");

            if (window.cordova){
                navigator.app.exitApp();
            }

            return state;


        case eventConstants.HIDE_NOTE_ACTION:
            reducerName(state, action);

            return {
                ...state,
                showSuccessNote: false
            };
        case eventConstants.NOTE_ACTION_SUCCESS:
            reducerName(state, action);

            return {
                ...state,
                showSuccessNote: true
            };

        case eventConstants.ENABLE_RECALL:
            reducerName(state, action);

            if(!isEmpty(action.payload)){
                if (state.recallIteration >= action.payload.recallLimit) {
                    return {
                        ...state
                    }
                }
            }

            return {
                ...state,
                recallEnabled: true
            };

        case eventConstants.RECALL_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);

            return {
                ...state,
                recallEnabled: false,
                recallIteration: state.recallIteration + 1
            };

        case eventConstants.SUCCESS_DETECT:
            reducerName(state, action);
            if(!isEmpty(action.payload.response.inwork)){
                return{
                    ...state,
                    ticketInfo: action.payload.response.inwork.info,
                    status: globalConstants.OPERATOR_OPEN,
                    activeButton: buttonsConstants.NEXT_BUTTON,
                    acceptMiddleState: false,
                    ticketStatus: action.payload.response.inwork.status,
                    ticketNumber: action.payload.response.inwork.ticket,
                    inworkData: action.payload.response.inwork,
                    inputFieldValue: action.payload.response.inwork.note,
                    ticketHtml: action.payload.response.inwork.info_html,
                    hideContent: false
                };
            }
            return{
                ...state,
                ticketInfo: []
            };

        case eventConstants.POSTPONE_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };

        case eventConstants.TRANSFER_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };

        case eventConstants.FINISH_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true
            };

        case eventConstants.TAKE_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                status: globalConstants.OPERATOR_OPEN,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketInfo: [...action.payload.response.info],
                ticketHtml: action.payload.response.info_html,
                ticketStatus: 4,
                ticketNumber: action.payload.response.ticket,
                response: {},

                hideContent: false,
                recallIteration: 0,
                 recallEnabled: true
            };

        case eventConstants.DROP_BUTTON_ACTION_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                status: globalConstants.OPERATOR_WAITING,
                activeButton: buttonsConstants.NEXT_BUTTON,

                ticketStatus: -1,
                ticketNumber: -1,
                response: {},

                hideContent: true,
                recallIteration: 0,
                recallEnabled: true
            };

        case eventConstants.NEXT_ACTION_SUCCESS:
            reducerName(state, action);
            let theTicketStatus;
            if (action.payload.request.status === "ok") {
                theTicketStatus = 2;
            } else{
                theTicketStatus = action.payload.response.status;
            }

            let newInputVal = "";
            if(action.payload.response.note){
                newInputVal = action.payload.response.note
            }
            return {
                ...state,
                status: globalConstants.OPERATOR_OPEN,
                activeButton: buttonsConstants.NEXT_BUTTON,
                ticketInfo: [...action.payload.response.info],
                ticketHtml: action.payload.response.info_html,
                inputFieldValue: newInputVal,
                ticketStatus: theTicketStatus,
                ticketNumber: action.payload.response.ticket,
                response: {},
                hideContent: false,
                recallIteration: 0,
                recallEnabled: true
            };


        case eventConstants.NEXT_ACTION_OPERATOR:
            reducerName(state, action);
            return state;


        case eventConstants.UPDATE_OPERATOR_CONTENT:
            if (!isEqual(action.payload.response, state.oldFeedData)) {
                reducerName(state, action);

                let newData = cloneDeep(action.payload.response);

                if (state.acceptMiddleState === true) {
                    if (action.inwork) {
                        return {
                            ...state,
                            oldFeedData: {...newData},
                            status: globalConstants.OPERATOR_OPEN,
                            activeButton: buttonsConstants.NEXT_BUTTON,
                            perms: {...action.payload.response.perms},
                            postponed: [...action.payload.response.postponed],
                            dropped: [...action.payload.response.dropped],
                            manual: [...action.payload.response.manual],
                            acceptMiddleState: false,
                            ticketInfo: [...action.payload.response.inwork.info],
                            ticketHtml: action.payload.response.inwork.info_html,
                            ticketStatus: action.payload.response.inwork.status,
                            ticketNumber: action.payload.response.inwork.ticket,
                            inworkData: action.payload.response.inwork,
                            hideContent: false
                        };
                    }
                }


                return {
                    ...state,
                    oldFeedData: {...newData},
                    perms: {...action.payload.response.perms},
                    postponed: [...action.payload.response.postponed],
                    dropped: [...action.payload.response.dropped],
                    manual: [...action.payload.response.manual]
                };
            }
            return state;


        case buttonsConstants.BUTTON_EVENT:
            reducerName(state, action);

            return {
                ...state,
                activeButton: action.payload.butType,
                hideContent: false
            };

        case eventConstants.LOGOUT_SUCCESS:
            reducerName(state, action);
            return {
                ...state,
                ...initialState
            };

        default:
            return state;
    }
};

const reducerName = (state, action) => {
   // if (globalConstants.DEV_ENABLE_LOG === true) {
        console.log("--- REDUCER: operatorStateDetect     ", action.type);
        console.log("state:  ", state);
        console.log("action:  ", action);
  //  }
};

export default operatorState;